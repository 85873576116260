function phoneReg(phone) {
  return new Promise((resolve, reject) => {
    let reg =
      /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
    if (!reg.test(phone) || phone.length !== 11) {
      reject('请输入正确的手机号')
    } else {
      resolve(true)
    }
  })
}

function carIdReg(carId) {
  return new Promise((resolve, reject) => {
    let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    let _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
    // 校验身份证：
    if (_IDRe18.test(carId) || _IDre15.test(carId)) {
      resolve(true)
    } else {
      reject('身份证号码不正确')
    }
  })
}

module.exports = {
  phoneReg,
  carIdReg
}
