<template>
  <div class="page flex_center">
    <div class="nav flex_direction_column">
      <div class="nav_item flex">
        <span
          v-for="(item, index) in nav"
          class="flex"
          :class="{ navActive: index == navIndex }"
          :key="index"
          @click="navClick(index)"
          >{{ item }}
          <p class="p1" :style="index == navIndex ? 'height:40px' : 'height:14px'"></p>
        </span>
      </div>
      <div class="form">
        <!-- 密码登录 -->
        <el-form v-if="navIndex == 0" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="手机号" prop="mobile">
            <el-input placeholder="请输入手机号" v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input show-password placeholder="请输入密码" v-model="ruleForm.password"></el-input>
          </el-form-item>
        </el-form>
        <!-- 验证码登录 -->
        <el-form v-if="navIndex == 1" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="手机号" prop="mobile">
            <el-input placeholder="请输入手机号" v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="手机验证" prop="code">
            <el-input placeholder="请输入验证码" v-model="ruleForm.code"></el-input>
            <div class="send" :class="{ sendActive: isSend }" @click="sendCode">
              {{ isSend ? '短信已发送' : '发送验证码' }}
            </div>
          </el-form-item>
        </el-form>
        <div class="box flex_space_between">
          <span @click="loginClick">登录</span>
          <span @click="$router.push('/account-register')">立即注册</span>
        </div>
        <!-- <div class="forget">忘记密码</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { phoneReg } from '@/utils/regular.js'
import { postLogin, postCode, getMobileCode } from '@/service/api/user'
const validatePhone = (rule, value, callback) => {
  phoneReg(value)
    .then(() => {
      callback()
    })
    .catch((err) => {
      return callback(new Error(err))
    })
}
export default {
  data() {
    return {
      isSend: false,
      navIndex: 0,
      nav: ['密码登录', '验证码登录'],
      ruleForm: {
        mobile: '',
        password: '',
        code: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      codeTime: 60,
      isToHome: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      console.log(to, from)
      if (from.path == '/account-register') {
        vm.isToHome = true
      } else {
        vm.isToHome = false
      }
    })
  },
  mounted() {},

  methods: {
    // 登录
    async postLogin() {
      let res = ''
      if (this.navIndex == 0) {
        // 密码登录
        res = await postLogin(this.ruleForm)
      } else {
        // 验证码登录
        res = await postCode(this.ruleForm)
      }
      if (res.code == 1) {
        if (this.isToHome) {
          this.$router.push('/')
        } else {
          this.$router.go(-1)
        }
        this.$message.success('登录成功')
        this.$store.state.userInfo = res.msg
        localStorage.setItem('user-info', JSON.stringify(res.msg))
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取验证码
    async getMobileCode() {
      const res = await getMobileCode({
        mobile: this.ruleForm.mobile
      })
      if (res.code == 1) {
        var timer = null
        this.isSend = !this.isSend
        timer = setInterval(() => {
          this.codeTime = this.codeTime - 1
          if (this.codeTime == 0) {
            this.codeTime = 60
            this.isSend = !this.isSend
            clearInterval(timer)
          }
        }, 1000)
      } else {
        this.$message.error(res.msg)
      }
    },
    navClick(index) {
      this.navIndex = index
      this.$refs.ruleForm.resetFields()
    },
    sendCode() {
      if (this.ruleForm.mobile == '') return this.$message.warning('请填写手机号')
      phoneReg(this.ruleForm.mobile).then(() => {
        if (!this.isSend) {
          // 获取验证码
          this.getMobileCode()
        } else {
          this.$message.warning(`${this.codeTime}后可重新发送`)
        }
      })
    },
    loginClick() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.postLogin()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../css/common.less');
/deep/ .el-form-item__label {
  font-size: 16px;
}
.page {
  .navActive {
    color: @color_one;
    font-weight: bold;
    // &::before {
    //   width: 1px;
    //   content: '';
    //   height: 40px;
    //   margin-right: 14px;
    //   background-color: #ccc;
    // }
  }
  .nav {
    width: 404px;
    .nav_item {
      cursor: pointer;
      span {
        position: relative;
        margin: 0 40px;
        height: 37px;
        line-height: 37px;
        font-size: @font_size_26;
      }
      .p1 {
        left: -40px;
        height: 40px;
        // height: 14px;
        width: 1px;
        background-color: #ccc;
        position: absolute;
      }
    }
  }
  .form {
    .forget {
      height: 20px;
      cursor: pointer;
      margin-top: 16px;
      color: #468eff;
      line-height: 25px;
      font-size: @font_size_14;
    }
  }
}
</style>
